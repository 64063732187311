import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-[#12141e] pt-12">
      {/* footer top */}
      <div className="container">
        <div className="sm:flex items-center justify-between md:gap-8">
          <div className="w-full sm:w-1/2">
            <h2 className="text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]">
              Do you wan tto make beautiful product?
            </h2>
            <a href="#">
              <button
                className="bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300
                py-2 px-4 rounded-[8px]"
              >
                <i class="ri-mail-line"></i>
                Hire me
              </button>
            </a>
          </div>

          <div className="w-full sm:w-1/2">
            <p className="text-gray-300 leading-7 mt-4 sm:mt-0">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas
              non reprehenderit cum? Mollitia deleniti eius voluptatibus
              inventore nihil fuga.
            </p>

            <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
              <span className="text-gray-300 font-[600] text-[15px] ">
                Follow Me:{" "}
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] cursor-pointer text-center rounded-full flex  items-center justify-center ">
                <a className="text-gray-300 font-[500] text-[18px]" href="#">
                  <i class="ri-facebook-fill "></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] cursor-pointer text-center rounded-full flex  items-center justify-center ">
                <a className="text-gray-300 font-[500] text-[18px]" href="#">
                  <i class="ri-github-fill "></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] cursor-pointer text-center rounded-full flex  items-center justify-center ">
                <a className="text-gray-300 font-[500] text-[18px]" href="#">
                  <i class="ri-facebook-fill "></i>
                </a>
              </span>
              <span className="w-[35px] h-[35px] bg-[#2b2d33] cursor-pointer text-center rounded-full flex  items-center justify-center ">
                <a className="text-gray-300 font-[500] text-[18px]" href="#">
                  <i class="ri-facebook-fill "></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* footer top end*/}
      {/* footer bottom */}

      <div className="bg-[#1b1e29] py-5 mt-14">
        <div className="container">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <span className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#2b2d33] text-white font-[500] text-[18px]">C</span>
                <div className="leading-[20px]">
                  <h2 className="text-gray-200 font-[500] text-[18px]">Chanawee (Seng) Prinyarux</h2>
                  <p className="text-gray-400 text-[14px]">Personal</p>

                </div>
              </div>
            </div>
            <div>
              <p className="text-gray-400 text-[14px]">Copyright {year} developed by Chanawee - All right reserved.</p>
            </div>
             </div>
          </div>
      </div>
      {/* footer bottom end*/}
    </footer>
  );
};

export default Footer;
