import React , {useState, useEffect }from "react";
import data from "../../assets/data/portfolioData.js";

import Modal from "./Modal.jsx";
const Portfolio = () => {
    const [nextItems, setNextItems] = useState(6);
    const [portfolios, setPortfolios] = useState(data);
    const [selectTab, setSelectTab] = useState("all");
    const [showModal, setShowModal] = useState(false);
    const [activeID, setActiveID] = useState(null);

    const loadMoreHandler = () => {
        setNextItems(prev => prev + 3); 
    };

    const showModalHandler = (id) => {
        setShowModal(true);
        setActiveID(id);
    };

    useEffect(() => {
        if (selectTab === "all") {
            setPortfolios(data);
        } else {
            const filteredData = data.filter((portfolio) =>
            portfolio.category.includes(selectTab)
            );
            setPortfolios(filteredData);
        }
    }, [selectTab]);

  return (
    <section className="bg-slate-100" id="portfolio">
      <div className="container ">
        <div className="flex items-center justify-between flex-wrap">
          <div className="mb-7 sm:mb-0">
            <h3 className="text-headingColor text-[2rem] font-[700]">
              My recent projects
            </h3>
          </div>
          <div className="flex gap-3">
            <button onClick={()=>setSelectTab("all")} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]">
              All
            </button>
            <div className="flex gap-3">
              <button onClick={()=>setSelectTab("Web Design")} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]">
                Web Design
              </button>
            </div>
            <div className="flex gap-3">
              <button onClick={()=>setSelectTab("Ux")} className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[8px]">
                UI Design
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 flex-wrap mt-12">
          {portfolios?.slice(0,nextItems)?.map((portfolio, index) => (
            <div
            key = {index}
              data-aos="fade-zoom-in"
              data-aos-delay="50"
              data-aos-duration="1000"
              className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1]"
            >
              <figure>
                <img
                  key={index}
                  src={portfolio.imgUrl}
                  alt=""
                  className="rounded-[8px]"
                />
              </figure>

              <div className="w-full h-full bg-primaryColor bg-opacity-50 absolute top-0 left-0 z-[5] hidden group-hover:block">
                <div className="w-full h-full flex items-center justify-center">
                  <button onClick={() => showModalHandler(portfolio.id)} className="text-white bg-headingColor hover:bg-smallTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200">
                    See Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-6">
            {nextItems < portfolios.length && data.length > 6 && (
                <button onClick={loadMoreHandler} className="text-white bg-primaryColor hover:bg-smallTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200">
                Load more
                </button>
            )}

        </div>
      </div>

      {showModal && (
        <Modal activeID={activeID} setShowModal={setShowModal} />
      )}

    </section>
  );
};

export default Portfolio;
