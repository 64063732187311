import React from "react";
import fontendImg from "../../assets/images/front-end.png";
import backendImg from "../../assets/images/backend.png";
import appImg from "../../assets/images/apps.png";
import uiImg from "../../assets/images/design.png";

const Services = () => {
  return (
    <section className="" id="services">
      <div className="container lg:pt-5">
        <div className="text-center">
          <h2 className="text-headingColor font-[700] text-[2.4rem] mb-5">
            What do I help
          </h2>
          <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam iste
            itaque numquam veniam minus architecto cupiditate nisi natus
            corporis sed repudiandae id eos repellat modi excepturi deserunt
            placeat, omnis hic.
          </p>
        </div>

        <div className="flex flex-col justify-center sm:py-12">
          <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
            <div className="relative text-gray-700 antialiased text-sm text-semibold">
              {/* vertical line running throught the middle */}
              <div className="hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform -translate-x-1/2 "></div>

              {/* left card 1 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto item-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-left"
                        data-aos-duration="1200"
                        className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-primaryColor font-[70] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Frontend Development
                        </h3>
                        <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                          Laborum, dolore ipsum, deleniti neque delectus dolorum
                          explicabo corporis tempora velit sequi itaque facilis.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Image in the middler line */}
                  <div className="">
                    <figure className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center">
                      <img src={fontendImg} alt="" className="w-full h-full" />
                    </figure>
                  </div>
                  {/* Image in the middler line end */}
                </div>
              </div>
              {/* end left card */}

              {/* right card 1 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto item-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="200"
                        data-aos-duration="1300"
                        className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-primaryColor font-[70] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Frontend Development
                        </h3>
                        <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Image in the middler line */}
                  <div className="">
                    <figure className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center">
                      <img src={backendImg} alt="" className="w-full h-full" />
                    </figure>
                  </div>
                  {/* Image in the middler line end */}
                </div>
              </div>
              {/* end right card */}

              {/* left card 2 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-start w-full mx-auto item-center">
                    <div className="w-full sm:w-1/2 sm:pr-8">
                      <div
                        data-aos="fade-left"
                        data-aos-delay="300"
                        data-aos-duration="1400"
                        className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-primaryColor font-[70] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Frontend Development
                        </h3>
                        <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                          Laborum, dolore ipsum, deleniti neque delectus dolorum
                          explicabo corporis tempora velit sequi itaque facilis.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Image in the middler line */}
                  <div className="">
                    <figure className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center">
                      <img src={appImg} alt="" className="w-full h-full" />
                    </figure>
                  </div>
                  {/* Image in the middler line end */}
                </div>
              </div>
              {/* end left card */}

              {/* right card 2 */}
              <div className="mt-6 sm:mt-0 sm:mb-12">
                <div className="flex items-center flex-col sm:flex-row">
                  <div className="flex justify-end w-full mx-auto item-center">
                    <div className="w-full sm:w-1/2 sm:pl-8">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-duration="1500"
                        className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150"
                      >
                        <h3 className="text-primaryColor font-[70] mb-3 group-hover:text-white group-hover:font-[600] text-xl">
                          Frontend Development
                        </h3>
                        <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Image in the middler line */}
                  <div className="">
                    <figure className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center">
                      <img src={uiImg} alt="" className="w-full h-full" />
                    </figure>
                  </div>
                  {/* Image in the middler line end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
