import React from "react";
import portfolios from "../../assets/data/portfolioData";
const Modal = ({ activeID, setShowModal }) => {
  const portfolio = portfolios.find((portfolio) => portfolio.id === activeID);
  // console.log(portfolio)

  return (
    <div className="w-full h-full fixed top-0 left-0 z-10 bg-headingColor bg-opacity-40">
      <div className="w-11/12 md:max-w-[600px] md:w-full absolute top-1/2 left-1/2 z-20 bg-white rounded-[8px] transform -translate-x-1/2 -translate-y-1/2 p-5">
        <div>
          <figure>
            <img src={portfolio.imgUrl} alt="" className="rounded-[8px]" />
          </figure>
        </div>
        <div>
          <h2 className="text-headingColor text-2xl font-[700] my-5">
            {portfolio.title}
          </h2>
          <p className="text-smallTextColor text-[15px] leading-7">
            {portfolio.description}
          </p>

          <div className="mt-5 flex items-center gap-3 flex-wrap">
            <h4 className="text-headingColor text-[18px] text-[700]">
              Technologies:{" "}
            </h4>

            {portfolio.technologies.map((technology, index) => (
              <span
                key={index}
                className="bg-gray-200  py-1 px-2 text-[15px] rounded-[5px] text-smallTextColor  font-[600]"
              >
                {technology}
              </span>
            ))}
          </div>

          <a href={portfolio.siteUrl}>
            <button className="text-white bg-primaryColor hover:bg-smallTextColor my-8  py-2 px-4 rounded-[8px] font-[500] ease-in duration-200">
              See Live
            </button>
          </a>
        </div>
        <button  onClick={() => setShowModal(false)} className="leading-0 cursor-pointer rounded-[3px] w-[1.8rem] h-[1.8rem] bg-white  absolute top-[1.7rem] right-[1.7rem] text-[25px] flex items-center"> <i class="ri-close-line text-[30px] text-headingColor"></i></button>    
      </div>
    </div>
  );
};

export default Modal;
