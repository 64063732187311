import React from "react";

const Contact = () => {
  return (
    <section className="bg-slate-100 pb-16" id="contact">
      <div className="container">
        <h2 className="text-headingColor font-[700] text-[2.5rem] mb-8">
            Get in touch
        </h2>
        <div className="md:flex justify-between items-center">
          <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
            <iframe
            className="border-0 w-full h-full rounded-[8px]"
                title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.8003238413457!2d-94.6577681228264!3d38.9285411717175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0ea2fe7d6bbab%3A0x247b1b968b13f62d!2sOverland%20Park%20Convention%20Center!5e0!3m2!1sen!2sus!4v1696117015172!5m2!1sen!2sus"
             
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="w-full md:w-1/2 md:mx-5">
            <form className="flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <label htmlFor="name" className="text-smallTextColor">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="py-2 px-4 rounded-[8px] border border-solid border-smallTextColor"
                />
              </div>
              <div className="flex flex-col gap-3">
                <label htmlFor="email" className="text-smallTextColor">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="py-2 px-4 rounded-[8px] border border-solid border-smallTextColor"
                />
              </div>
              <div className="flex flex-col gap-3">
                <label htmlFor="message" className="text-smallTextColor">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  className="py-2 px-4 rounded-[8px] border border-solid border-smallTextColor"
                ></textarea>
              </div>
              <button className="bg-[#FF4D00] py-2 px-4 rounded-[8px] text-white font-[700] text-[1.2rem]">
                Send Message
              </button>
            </form>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
