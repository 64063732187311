import React, {useRef, useEffect} from "react";

const Header = () => {

  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const stickyHeaderFunction = () => {
    window.addEventListener("scroll", () => {
     if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
      headerRef.current.classList.add("sticky__header");
      menuRef.current.classList.remove("show__menu");
     }else{ 
      headerRef.current.classList.remove("sticky__header");
      menuRef.current.classList.remove("show__menu");
     }

    });
  };

  useEffect(() => {
    stickyHeaderFunction();
    return window.removeEventListener("scroll", stickyHeaderFunction);
  }, []);

  const handleClick = e =>{
    e.preventDefault();
    const targetAttr = e.target.getAttribute("href");
    const location = document.querySelector(targetAttr).offsetTop;
    window.scrollTo({
      left: 0,
      top: location - 80,
    });
  }

  const toggleMenu = () =>  menuRef.current.classList.toggle("show__menu");
  

  return (
    <header ref={headerRef} className="w-full h-[80px] leading-[80px] flex items-center">
      <div className="container">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center gap-[10px]">
            <span className="w-[35px] h-[35px] bg-primaryColor text-white text-[18px] font-[500] rounded-full flex items-center justify-center">
              C
            </span>
            <div className="leading-[20px]">
              <h2 className="text-xl text-smallTextColor font-[700]">Chanawee (Seng) Prinyarux</h2>
              <p className="text-smallTextColor text-[18px] font-[500]">Personal</p>
            </div>
            </div>  
          {/* logo end */}
          {/* menu start */}
            <div className="menu" ref={menuRef} onClick={toggleMenu}>
              <ul className="flex items-center gap-10">
               <li> <a onClick={handleClick} className="cursor-pointer text-smallTextColor font-[600]" href="#about">About</a> </li>
               <li>  <a onClick={handleClick}  className="cursor-pointer text-smallTextColor font-[600]" href="#services">Service</a> </li>
               <li>  <a onClick={handleClick} className="cursor-pointer text-smallTextColor font-[600]" href="#portfolio">Porfolio</a> </li>
               <li>  <a onClick={handleClick} className="cursor-pointer text-smallTextColor font-[600]" href="#contact">Contact</a> </li>
              </ul>
            </div>
          {/* menu end */}
          {/* menu right */}
          <div className="flex items-center gap-4">
            <button className="hidden md:flex md:items-center md:gap-2 md:text-smallTextColor md:font-[600] md:border md:border-solid md:border-smallTextColor 
            md:py-2 md:px-4 md:rounded-[8px] md:max-h-[40px] md:hover:bg-smallTextColor md:hover:text-white hover:font-[500] ease-in duration-300">
            <i class="ri-send-plane-line"></i>

              Hire Me</button>
            <span onClick={toggleMenu} className="text-2xl text-smallTextColor md:hidden cursor-pointer"><i className="ri-menu-line"></i></span>
          </div>
          {/* menu end */}
          </div>
        
      </div>
    </header>
  );
};

export default Header;
