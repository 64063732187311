import React from "react";
import heroImg from "../../assets/images/hero.jpg";
import CountUp from "react-countup";

const Hero = () => {
  return (
    <section className="pt-0 bg-slate-100" id="about">
      <div className="container pt-14">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
          {/* hero left content */}
          <div className="w-full md:basis-1/2">
            <h5
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-headingColor font-[600] text-[16px]"
            >
              Hello Welcome
            </h5>
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              className="text-headingColor font-[800] text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5"
            >
              I'm Chanawee Prinyarux <br />
              Software Engineer Advisor
            </h1>

            <div
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200"
              className="flex items-center gap-6 mt-7"
            >
              <a href="#">
                <button
                  className="bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300
                py-2 px-4 rounded-[8px]"
                >
                  <i class="ri-mail-line"></i>
                  Hire me
                </button>
              </a>

              <a
                className="text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor"
                href="#portfolio"
              >
                <button>See portfolio</button>
              </a>
            </div>
            <p
              data-aos="fade-left"
              data-aos-duration="1800"
              data-aos-delay="200"
              className="flex gap-2 text-headingColor font-[500] text-[16px] leading-7 sm-pl-14 sm:pr-10 mt-7"
            >
              <span>
                <i class="ri-apps-2-line"></i>
              </span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam saepe
              ducimus voluptate ipsa quisquam autem odit laboriosam. Sint
              explicabo reiciendis vitae optio odio. Ratione autem dolor eaque,
              sint asperiores voluptates.
            </p>

            <div className="flex items-center gap-9 mt-14">
              <span className="text-smallTextColor text-[15px] font-[600]">
                {" "}
                Follow me:
              </span>
              <span>
                <a
                  text-smallTextColor
                  className="text-[15px] font-[600]"
                  href="#"
                >
                  <i class="ri-facebook-line text-[#3b5998] text-[18px]"></i>
                </a>
              </span>
              <span>
                <a
                  text-smallTextColor
                  className="text-[15px] font-[600]"
                  href="#"
                >
                  <i class="ri-github-fill text-[#3b5998] text-[18px]"></i>
                </a>
              </span>
              <span>
                <a
                  text-smallTextColor
                  className="text-[15px] font-[600]"
                  href="#"
                >
                  <i class="ri-instagram-line text-[#3b5998] text-[18px]"></i>
                </a>
              </span>
            </div>
          </div>
          {/* hero left content end */}

          {/* hero img */}
          <div className="md:basis-1/3 flex justify-center items-center mt-10 sm:mt-0">
            <figure data-aos="fade-left" data-aos-duration="1500">
              <img
                className="rounded-full shadow-primaryColor"
                src={heroImg}
                alt="hero"
              />
            </figure>
          </div>
          {/* hero img end */}
          {/* hero content */}
          <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end">
            <div className="mb-10">
              <h2 className="text-headingColor font-[700] text-[32px]">
                <CountUp start={0} end={15} duration={5} suffix="%" />
              </h2>
              <h4 className="text-headingColor font-[600] text-[20px]">
                Years of Experience
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-headingColor font-[700] text-[32px]">
                <CountUp start={0} end={100} duration={5} suffix="%" />
              </h2>
              <h4 className="text-headingColor font-[600] text-[20px]">
                Success Rate
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-headingColor font-[700] text-[32px]">
                <CountUp start={0} end={150} duration={5} suffix="+" />
              </h2>
              <h4 className="text-headingColor font-[700] text-[20px]">
                Happy Clients
              </h4>
            </div>
            <div className="mb-10">
              <h2 className="text-headingColor font-[700] text-[32px]">
                <CountUp start={0} end={210} duration={5} suffix="+" />
              </h2>
              <h4 className="text-headingColor font-[400] text-[20px]">
                Project Completed
              </h4>
            </div>
          </div>
          {/* hero content end */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
